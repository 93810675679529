import 'vanilla-cookieconsent/dist/cookieconsent';

window.addEventListener('load', function(){

    const cc = initCookieConsent();

    cc.run({
        current_lang:'en',
        autoclear_cookies: true,                   // default: false
        page_scripts: true,                        // default: false

        // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
        // delay: 0,                               // default: 0
        // auto_language: null                     // default: null; could also be 'browser' or 'document'
        // autorun: true,                          // default: true
        // force_consent: false,                   // default: false
        // hide_from_bots: true,                   // default: true
        // remove_cookie_tables: false             // default: false
        // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
        // cookie_expiration: 182,                 // default: 182 (days)
        // cookie_necessary_only_expiration: 182   // default: disabled
        // cookie_domain: location.hostname,       // default: current domain
        // cookie_path: '/',                       // default: root
        // cookie_same_site: 'Lax',                // default: 'Lax'
        // use_rfc_cookie: false,                  // default: false
        // revision: 0,                            // default: 0

        onFirstAction: function(user_preferences, cookie){
            // callback triggered only once
        },

        onAccept: function (cookie) {
            // ...
        },

        onChange: function (cookie, changed_preferences) {
            // ...
        },

        languages: {
            'en': {
                consent_modal: {
                    title: 'This website uses cookies',
                    description: 'We are using cookies to give you the best experience on our website. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                    primary_btn: {
                        text: 'Accept all',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Reject all',
                        role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Cookie preferences',
                    save_settings_btn: 'Save settings',
                    accept_all_btn: 'Accept all',
                    reject_all_btn: 'Reject all',
                    close_btn_label: 'Close',
                    cookie_table_headers: [
                        {col1: 'Name'},
                        {col2: 'Domain'},
                        {col3: 'Expiration'},
                        {col4: 'Description'}
                    ],
                    blocks: [
                        {
                            title: 'Cookie usage 📢',
                            description: 'We are using cookies to give you the best experience on our website. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/website-cookies" class="cc-link">cookie policy</a>.'
                        }, {
                            title: 'Strictly necessary cookies',
                            description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                            },
                            cookie_table: [             // list of all expected cookies
                                {
                                    col1: 'CraftSessionId',       // match all cookies starting with "_ga"
                                    col2: 'mha.co.uk',
                                    col3: 'Session',
                                    col4: 'This site relies on PHP sessions to maintain sessions across web requests. That is done via the PHP session cookie.',
                                    is_regex: true
                                },
                                {
                                    col1: 'CRAFT_CSRF_TOKEN',
                                    col2: 'mha.co.uk',
                                    col3: 'Session',
                                    col4: 'Protects us and you as a user against Cross-Site Request Forgery attacks.',
                                },
                                {
                                    col1: 'cc_cookie, cookie-consent',
                                    col2: 'mha.co.uk',
                                    col3: '6 months and 1 year respectively',
                                    col4: 'Lets us track your cookie preferences',
                                }
                            ]
                        }, {
                            title: 'Analytics cookies',
                            description: 'These cookies help us to understand how visitors interact with our website by collecting and reporting on information anonymously',
                            toggle: {
                                value: 'analytics',     // your cookie category
                                enabled: false,
                                readonly: false
                            },
                            cookie_table: [             // list of all expected cookies
                                {
                                    col1: '_ga, _gid and _gat',       // match all cookies starting with "_ga"
                                    col2: 'google.com',
                                    col3: '2 years for _ga, 1 day for _gid and _gat',
                                    col4: 'Used to send data to Google Analytics about the visitors device and behaviour. Tracks user across devices and marketing channels.',
                                    is_regex: true
                                },
                                {
                                    col1: '_clck _clsk',       // match all cookies starting with "_ga"
                                    col2: 'clarity.microsoft.com',
                                    col3: '1 year and one month',
                                    col4: 'Used to send data to Microsoft Clarity about the visitors use the website.',
                                    is_regex: true
                                },
                                {
                                    col1: 'ln_or',       // match all cookies starting with "_ga"
                                    col2: '.linkedin.com',
                                    col3: '1 day',
                                    col4: 'Used to send data to LinkedIn about conversion statistics.',
                                    is_regex: true
                                },
                            ]
                        }, {
                            title: 'More information',
                            description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/contact">contact us</a>.',
                        }
                    ]
                }
            }
        }
    });
});