import 'es6-promise/auto';
import 'whatwg-fetch';
import Cookies, {setEntryPoint} from '../utilities/cookies'

// Wait until the DOM is ready
document.addEventListener('DOMContentLoaded', (event) => {

    // Fetch all forms with the data attribute data-fui-form
    let $forms = document.querySelectorAll('.fui-form');

    $forms.forEach($form => {

        // Find the CSRF token hidden input for the current form, so we can replace it
        let $csrfInput = $form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');

        // Get the handle for the current form from the hidden input named "handle"
        let formHandle = $form.querySelector('input[name="handle"]').value;

        fetch('/actions/formie/forms/refresh-tokens?form=' + formHandle)
            .then(result => { return result.json(); })
            .then(result => {
                $csrfInput.outerHTML = result.csrf.input;

                // Find the JavaScript captcha hidden input, so we can update it
                if (result.captchas && result.captchas.javascript) {
                    // JavaScript captcha
                    let jsCaptcha = result.captchas.javascript;

                    $form.querySelector('input[name="' + jsCaptcha.sessionKey + '"]').value = jsCaptcha.value;
                }

                // Find the Duplicate captcha hidden input, so we can update it
                if (result.captchas && result.captchas.duplicate) {
                    // Duplicate captcha
                    let duplicateCaptcha = result.captchas.duplicate;

                    $form.querySelector('input[name="' + duplicateCaptcha.sessionKey + '"]').value = duplicateCaptcha.value;
                }

                // Update the form's hash (if using Formie's themed JS)
                if ($form.form && $form.form.formTheme) {
                    $form.form.formTheme.updateFormHash();
                }
            });

    });
    setEntryPoint()
});


// Event forms can have a hidden form field to have the event entry title in but formie doesn't support it other than via a module which seems overkill..
// So we set it here :(
/*
let form = document.querySelector('form input[name="handle"][value="event"]')
if (form) { // If the input exists
    form = form.form
    let h1Text = document.querySelector('h1').innerText // Grab the text from the first h1 element
    let targetField = form.querySelector('input[name="fields[eventEntryTitle]"]')
    if (targetField) { // If the target field exists
        targetField.value = h1Text // Set the value of the target field
    }
}
*/
