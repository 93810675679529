import { initializeCharts } from './stackedBarCharts';

document.addEventListener('DOMContentLoaded', function() {
    const tabs = document.querySelectorAll('.tab-link');
    const panels = document.querySelectorAll('.tab-panel');

    tabs.forEach(tab => {
        tab.addEventListener('click', () => {
            initializeCharts();
            // Deselect all tabs and hide all panels
            tabs.forEach(t => {
                t.setAttribute('aria-selected', 'false');
                t.setAttribute('tabindex', '-1');
            });
            panels.forEach(p => {
                p.hidden = true;
            });

            // Select the clicked tab and show the corresponding panel
            tab.setAttribute('aria-selected', 'true');
            tab.setAttribute('tabindex', '0');
            const panel = document.getElementById(tab.getAttribute('aria-controls'));
            panel.hidden = false;
        });
    });
});