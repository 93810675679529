// THIS IS HOW TO DO SOMETHING IN JS *AFTER* THE SCRIPTS ARE LOADED
document.addEventListener('vite-script-loaded', function (e) {
    if (e.detail.path === 'src/js/app.ts') {
        // The script with the path src/js/app.ts is now loaded, do whatever initialization is needed
    }
});

// This is run after blitz has injected it's stuff


    screen.width

    document.querySelector('.mainNav__burger').addEventListener('click', function(e) {
        e.preventDefault();
        document.querySelector('.mainNav__container').classList.toggle('mainNav__container--open');

        //TOGGLE OVERFLOW HIDDEN ON BODY ELEMENT
        document.querySelector('body').classList.toggle('overflow-hidden');
    });

    // CLOSE THE MOBILE NAV
    document.querySelector('.mainNav__burgerClose').addEventListener('click', function(e) {
        e.preventDefault();
        document.querySelector('.mainNav__container').classList.toggle('mainNav__container--open');
        // CLOSE ANY OPEN SUB-NAVS IN THE MOBILE MENU
        document.querySelector('.mainNav__container').classList.remove('mainNav__container--scrolled');
        document.querySelector('body').classList.toggle('overflow-hidden');

    });
    // IF SOMEONE CLICKS ON THE TOP LEVEL NAV THAT HAS CHILDREN, THEN WE SHOW THE CHILDREN
    document.querySelectorAll('.dropped__parent--top > a').forEach(elem => {
        elem.addEventListener('click', function(e) {
            if(window.innerWidth < 1024 ){
                e.preventDefault();
            }
            elem.parentElement.classList.toggle('dropped__parent--open');
            document.querySelector('.mainNav__container').classList.toggle('mainNav__container--scrolled');
        });
    });

    // HANDLE THE BACK BUTTON ON THE SUB NAV
    document.querySelectorAll('.dropped__back').forEach(elem => {
        elem.addEventListener('click', function(e) {
            e.preventDefault();
            document.querySelector('.dropped__parent--open').classList.remove('dropped__parent--open');
            document.querySelector('.mainNav__container').classList.toggle('mainNav__container--scrolled');
        });
    });


    // TOGGLE THE SEARCH IN THE HEADER
    const mainNavSearch = document.querySelector('.mainNav__search');
    const mainNavSearchClose = document.querySelector('.mainNav__search--close');
    const mainNavSearchIcon = document.querySelector('.mainNav__search--icon');
    const formSearch = document.querySelector('.form__search');
    const mainNavContainer = document.querySelector('.mainNav__container');
    const mainNavContact = document.querySelector('.mainNav__contact');
    const mainNavLocation = document.querySelector('.mainNav__location');
    const mainNavBurger = document.querySelector('.mainNav__burger');
    const globalHeader = document.querySelector('.globalHeader');
    const globalHeaderLogo = document.querySelector('.globalHeader__logo');

    mainNavSearch.addEventListener('click', function(e) {
        e.preventDefault();
        formSearch.classList.remove('hidden')
        mainNavContact.classList.add('lg:hidden','invisible', 'lg:visible')
        mainNavContainer.classList.add('lg:hidden')
        mainNavLocation.classList.add('lg:hidden')
        mainNavSearchIcon.classList.add('hidden')
        mainNavBurger.classList.add('hidden')
        globalHeader.classList.add('globalHeader--search')
        globalHeaderLogo.classList.add('invisible', 'md:visible')
    });

    mainNavSearchClose.addEventListener('click', function(e) {
        e.preventDefault();
        formSearch.classList.add('hidden')
        mainNavContact.classList.remove('lg:hidden','invisible', 'lg:visible')
        mainNavContainer.classList.remove('lg:hidden')
        mainNavLocation.classList.remove('lg:hidden')
        mainNavSearchIcon.classList.remove('hidden')
        mainNavBurger.classList.remove('hidden')
        globalHeader.classList.remove('globalHeader--search')
        globalHeaderLogo.classList.remove('invisible', 'md:visible')
    });
