document.addEventListener('DOMContentLoaded', function() {
    // Find all elements that need a tooltip.
    const hoverElements = document.querySelectorAll('.tooltip-inner');
    const tooltips = [];

    hoverElements.forEach(function(hoverElement, index) {
        // Create the tooltip element for each hoverElement.
        const tooltip = document.createElement('div');
        tooltip.classList.add('tooltip');
        tooltip.style.display = 'none'; // Ensure it's not displayed initially
        tooltips.push(tooltip); // Keep track of all tooltips for easy access

        // Append the tooltip to the container.
        hoverElement.parentNode.insertBefore(tooltip, hoverElement.nextSibling);

        const showTooltip = function() {
            // Hide all other tooltips first
            tooltips.forEach(t => t.style.display = 'none');

            // Get data attributes values.
            const heading = hoverElement.getAttribute('data-heading');
            const value = hoverElement.getAttribute('data-value');

            // Set the tooltip content.
            tooltip.innerHTML = `<strong>${heading}</strong>: ${value}`;

            // Adjust position and show the tooltip.
            tooltip.style.left = hoverElement.offsetLeft + 'px';
            tooltip.style.top = hoverElement.offsetTop + hoverElement.offsetHeight + 5 + 'px';
            tooltip.style.display = 'block';
        };

        const hideTooltip = function() {
            tooltip.style.display = 'none';
        };

        // Mouse events for non-touch devices
        hoverElement.addEventListener('mouseenter', showTooltip);
        hoverElement.addEventListener('mouseleave', hideTooltip);

        // Touch events for touch devices
        hoverElement.addEventListener('touchstart', function(event) {
            // Prevent the window from handling the touch event (e.g., scrolling).
            event.preventDefault();
            // If this tooltip is already displayed, it will be hidden; otherwise, it will be shown.
            const isDisplayed = tooltip.style.display === 'block';
            hideTooltip(); // Hide this tooltip first to reset state
            if (!isDisplayed) {
                showTooltip(); // Only show this tooltip if it was not already displayed
            }
        }, {passive: false}); // Use passive:false to enable preventDefault
    });

    // Optional: Listen for clicks or touches on the document to close all tooltips
    document.addEventListener('touchstart', function(event) {
        // Check if the touch event is outside of any hoverElement.
        if (![...hoverElements].some(element => element.contains(event.target))) {
            // Hide all tooltips if the touch is outside.
            tooltips.forEach(tooltip => tooltip.style.display = 'none');
        }
    }, {passive: true});
});
