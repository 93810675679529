export function setCookie(name, value, days) {
  let expires = ''
  if (days) {
    let date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export function getCookie(name) {
  let nameEQ = name + '='
  let ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export function setEntryPoint(name) {
  const entryPointCookie = getCookie('entryPointCookie')
  if (!entryPointCookie) {
    const currentUrl = window.location.href
    setCookie('entryPointCookie', currentUrl, 60)
  }
}
export default { getCookie, setCookie }