const accordionHeaders = document.querySelectorAll('[data-accordion-header]');
//console.log(accordionHeaders)
accordionHeaders.forEach(function (accordionHeader) {
    //console.log('were looping')
    let target = accordionHeader.parentElement.nextElementSibling;
    accordionHeader.onclick = () => {
        //console.log('were clicking')
        let expanded = accordionHeader.getAttribute('aria-expanded') === 'true' || false;
        accordionHeader.setAttribute('aria-expanded', !expanded);
        target.hidden = expanded;
    }
})

document.addEventListener('DOMContentLoaded', function() {
    const button = document.getElementById('jumpNavButton');
    const jumpNav = document.querySelector('.jumpNav');

    // Function to toggle 'hidden' class
    function toggleHidden() {
        jumpNav.classList.toggle('hidden');
    }

    if (button && jumpNav) {
        button.addEventListener('click', toggleHidden);

        // Adding event listeners to all <a> elements inside .jumpNav
        const links = jumpNav.querySelectorAll('a');
        links.forEach(link => {
            link.addEventListener('click', toggleHidden);
        });
    }
});
