import jquery from "jquery";
export default (window.$ = window.jQuery = jquery);

$('.modal__continue').on('click', function() {
	if($(this).find('.finish-line').length) {
		// Finished state
	} else {
		const currentInner = $(this).closest('.modal__content__inner');
		currentInner.hide();
		currentInner.next().toggle();
	}
});

$('.content-radio__item').on('click', function() {
	$('.content-radio__item').each(function() {
		if($(this).hasClass('active')) {
			$(this).removeClass('active');
		}
	});

	$(this).addClass('active');
});

$('.js-toggle-newsletter').on('click', function() {
	$('.modal--newsletter').addClass('active');
});

$('.js-toggle-event-filters').on('click', function(e) {
	e.preventDefault()
	$('.modal--event-filters').addClass('active');
});

$('.js-toggle-news-filters').on('click', function() {
	$('.modal--event-filters').addClass('active');
});

$('.js-toggle-insight-filters').on('click', function(e) {
	e.preventDefault();
	$('.modal--event-filters').addClass('active');
});

$('.js-toggle-vacancies-filters').on('click', function(e) {
	e.preventDefault();
	$('.modal--event-filters').addClass('active');
});

$('.js-toggle-contact').on('click', function() {
	$('.modal--contact').addClass('active');
});

$('.js-toggle-event').on('click', function() {
	$('.modal--event').addClass('active');
});

const buttons = document.querySelectorAll('.modal__closeTrigger');
buttons.forEach((button) => {
	button.addEventListener('click', (event) => {
		event.preventDefault(); // Prevent default behavior of the button
		const grandparent = button.closest('.modal');
		grandparent.classList.remove('active')
	});
});

$('.finish-line').on('click', function() {
	$(this).closest('.modal').removeClass('active');
	$('.modal__content__inner').hide();
	$('.modal__content .modal__content__inner:first-child').toggle();
})

// Add event listener to all buttons that go to contact page so that when clicked, they launch the contact modal.
const modalContact = document.querySelector('.modal--contact');
const modalNewsletter = document.querySelector('.modal--newsletter');
const modalEvent = document.querySelector('.modal--event');
	const links = document.querySelectorAll('a[href]');
	links.forEach(function(link) {
		// Get the href attribute value
		var href = link.getAttribute('href');

		// Create a URL object to easily access pathname, taking into account relative and absolute URLs
		const url = new URL(href, window.location.href);
		// Get the pathname and remove double slashes at the beginning, if any
		const pathname = url.pathname

		// Split the pathname into segments
		const segments = pathname.split('/').filter(Boolean);

		// Get the staff data attrs

		// Check if the data-first attribute exists and has a usable value
		const dataStart = link.getAttribute('data-start');
		if (dataStart) {
			console.log(`Data-first attribute found with value: ${dataStart}`);
		}

		// Check if the data-first attribute exists and has a usable value
		const dataEnd = link.getAttribute('data-end');
		if (dataEnd) {
			console.log(`Data-end attribute found with value: ${dataEnd}`);
		}

		if (segments.length === 1 && segments[0] === 'contact') {
			link.addEventListener('click', function(event) {
				event.preventDefault();
				document.querySelectorAll('a[href]')
				modalContact.classList.add('active');
				if (dataStart && dataEnd) {
					// Select the div element with the data-field-handle="partner" attribute
					const partnerDiv = modalContact.querySelector('div[data-field-handle="partner"]');
// Check if the partnerDiv exists
					if (partnerDiv) {
						// Find the input element inside the partnerDiv
						const inputElement = partnerDiv.querySelector('input');

						// Check if the inputElement exists
						if (inputElement) {
							// Set the value of the input element to "blah"
							inputElement.value = dataStart + '@' + dataEnd;
						}
					}
				}
			});
		}

		if (segments.length === 1 && segments[0] === 'subscribe') {
			link.addEventListener('click', function(event) {
				event.preventDefault();
				document.querySelectorAll('a[href]')
				modalNewsletter.classList.add('active');
			});
		}
	});
	// When the contact modal close link is clicked
	const modalCloseContact = document.querySelector('.modal__close--contact');
	if (modalCloseContact) {
		modalCloseContact.addEventListener('click', function() {
			modalContact.classList.remove('active');
		});
	}

	const modalCloseContactInner = document.querySelector('.modal__close--contactInner');
	if (modalCloseContactInner) {
		modalCloseContactInner.addEventListener('click', function() {
			modalContact.classList.remove('active');
		});
	}

	const modalCloseNewsletter = document.querySelector('.modal__close--newsletter');
	if (modalCloseNewsletter) {
		modalCloseNewsletter.addEventListener('click', function() {
			modalNewsletter.classList.remove('active');
		});
	}

	const modalCloseNewsletterInner = document.querySelector('.modal__close--newsletterInner');
	if (modalCloseNewsletterInner) {
		modalCloseNewsletterInner.addEventListener('click', function() {
			modalNewsletter.classList.remove('active');
		});
	}

const modalCloseEvent = document.querySelector('.modal__close--event');
if (modalCloseEvent) {
	modalCloseEvent.addEventListener('click', function() {
		modalEvent.classList.remove('active');
	});
}

const modalCloseEventInner = document.querySelector('.modal__close--eventInner');
if (modalCloseEventInner) {
	modalCloseEventInner.addEventListener('click', function() {
		modalEvent.classList.remove('active');
	});
}