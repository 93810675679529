import Vivus from 'vivus';

// Homemade version of the AOS library :)

// Just doing some animations on scroll here

document.addEventListener("DOMContentLoaded", function() {
    if (window.innerWidth > 1024) {
        let animatedElements = document.querySelectorAll('.animate-on-scroll, .animate-on-scroll--header');
        let observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // The element is in the viewport
                    let animationSpeed = entry.target.getAttribute('data-speed') || '0.5s';
                    entry.target.style.transition = `opacity ${animationSpeed}, transform ${animationSpeed}`;
                    entry.target.classList.add('visible');
                }
                // If you want the animation to play every time the element comes into view,
                // you can also remove the 'visible' class when it's not intersecting.
                // else {
                //     entry.target.classList.remove('visible');
                // }
            });
        });
        animatedElements.forEach(element => {
            observer.observe(element);
        });
    }
});

// Animate the growth symbol on spotlight on hero
document.addEventListener('DOMContentLoaded', function() {
    const element = document.getElementById('animate-growth');
    if (element) {
        new Vivus('animate-growth', {
            type: 'sync',
            duration: 100,
            pathTimingFunction: Vivus.LINEAR,
            //animTimingFunction: Vivus.EASE_IN,
        }, function doDone(obj) {
            obj.el.classList.add('finished');
        });
    }
});
