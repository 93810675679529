import ApexCharts from 'apexcharts'
const initializeCharts = () => {
    const baseOptions = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show:false,
            },
            animations: {
                enabled: false  // Turn off initial animations
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            }
        },
    };

    // A map to store chart references
    const chartMap = new Map();

    const charts = document.querySelectorAll(".stackedBarChart");
    charts.forEach((chartElement) => {
        const seriesData = JSON.parse(chartElement.getAttribute("data-series"));
        const xaxisData = JSON.parse(chartElement.getAttribute("data-xaxis"));
        const options = {
            ...baseOptions,
            series: seriesData,
            xaxis: {
                categories: xaxisData,
                labels: {
                    formatter: function (val) {
                        return val + "K"
                    }
                },
                yaxis: {
                    title: {
                        text: undefined
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + "K"
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            },
            colors: ['#307fe2','#00BAB3','#FF6C54','#FF8C3B','#319B42'],
        };
        const chart = new ApexCharts(chartElement, options);
        chart.render();

        // Store the chart reference in the map
        chartMap.set(chartElement, chart);
    });

    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1  // Adjust as needed
    };

    const chartObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // Access and destroy the existing chart instance associated with the intersected element
                const currentChart = chartMap.get(entry.target);
                if (currentChart) {
                    currentChart.destroy();
                }
                const seriesData = JSON.parse(entry.target.getAttribute("data-series"));
                const xaxisData = JSON.parse(entry.target.getAttribute("data-xaxis"));
                const options = {
                    ...baseOptions,
                    series: seriesData,
                    xaxis: {
                        categories: xaxisData
                    },
                    colors: ['#307fe2','#00BAB3','#FF6C54','#FF8C3B','#319B42'],
                    chart: {
                        ...baseOptions.chart,
                        animations: {
                            enabled: true
                        }
                    }
                };

                // Create a new chart instance with animations enabled for the intersected element
                const animatedChart = new ApexCharts(entry.target, options);
                animatedChart.render();

                // Store the new animated chart in the map
                chartMap.set(entry.target, animatedChart);

                // Optional: Unobserve if you want the animation to play only once
                chartObserver.unobserve(entry.target);
            }
        });
    }, observerOptions);

    // Start observing all the chart containers
    charts.forEach((chartElement) => {
        chartObserver.observe(chartElement);
    });
};

export { initializeCharts };

document.addEventListener('DOMContentLoaded', () => {
    initializeCharts();
});


