// Select all image elements with the data-animated attribute
let imgElements = document.querySelectorAll('img[data-animated]');

imgElements.forEach(function(imgElement) {
    // Add event listener for mouse enter (hover)
    imgElement.addEventListener('mouseenter', function() {
        let currentSrc = imgElement.getAttribute('src');
        let animatedSrc = imgElement.getAttribute('data-animated');
        imgElement.setAttribute('src', animatedSrc);
        imgElement.setAttribute('data-animated', currentSrc);
    });

    // Add event listener for mouse leave (unhover)
    imgElement.addEventListener('mouseleave', function() {
        let currentSrc = imgElement.getAttribute('src');
        let animatedSrc = imgElement.getAttribute('data-animated');
        imgElement.setAttribute('src', animatedSrc);
        imgElement.setAttribute('data-animated', currentSrc);
    });
});